import React from "react"
import querystring from "query-string"
import fetchPonyfill from "fetch-ponyfill"
import "./reset.css"
import Layout from "../components/layout"
import SEO from "../components/seo"
import passwordGraphic from "../images/password-graphic.svg"

const { fetch } = fetchPonyfill()

class ResetPassword extends React.Component {
  state = { password1: "", password2: "", success: false }
  submit() {
    this.setState({ error: "" })
    const { token, success } = querystring.parse(this.props.location.search)
    //console.log(token, success)

    if (!this.state.password1 || !this.state.password2) {
      this.setState({ error: "Password is empty" })
      return
    }

    if (this.state.password1.length < 6) {
      this.setState({
        error: "Password needs to be at least 6 characters long.",
      })
      return
    }

    if (this.state.password1 !== this.state.password2) {
      this.setState({ error: "Passwords don't match" })
      return
    }

    fetch(`${process.env.GATSBY_API_URL}/users/password/reset`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token,
        password: this.state.password1,
        password_confirmation: this.state.password2,
      }),
    })
      .then(data => {
        data.json().then(body => {
          console.log(body, data)
          if (body.error) {
            this.setState({ error: body.error.message })
          } else {
            this.setState({ success: true })
          }
        })
      })
      .catch(error => {
        console.log("error", error)
      })
  }
  render() {
    return (
      <Layout page="reset_password">
        <SEO title="Reset password" />
        <div className="flex-grid reset-container">
          <div style={{ flex: "1" }} />
          <div style={{ flex: "0 1 1000px" }}>
            <div className="flex-grid reset-grid">
              <div className="col reset-col">
                {this.state.success ? (
                  <div className="block-sent">
                    <div style={{ fontSize: "26px", fontWeight: "bold" }}>
                      Your password was successfully changed.
                    </div>
                    <div style={{ fontSize: "17px", marginTop: "15px" }}>
                      Return to the app and login using your new password.
                    </div>
                    <div className="only-mobile go-to-app">
                      <a
                        href={querystring.parse(this.props.location.search).success}
                        className="contact-submit-button"
                        style={{ display: "block", textDecoration: "none" }}
                      >
                        Go to app
                      </a>
                    </div>
                  </div>
                ) : null}
                {!this.state.success ? (
                  <>
                    <div className="reset-title">Forgot your password?</div>
                    <div className="reset-title reset-subtitle">
                      Reset it here.
                    </div>
                    {this.state.error ? (
                      <>
                        <div className="form-error" style={{ float: "left" }}>
                          {this.state.error}
                        </div>
                        <br />
                      </>
                    ) : null}
                    <div className="contact-form-label reset-label">
                      New password
                    </div>
                    <input
                      type="password"
                      className="contact-form-input contact-small-input"
                      placeholder="Placeholder"
                      value={this.state.password1}
                      onChange={e =>
                        this.setState({ password1: e.target.value })
                      }
                    ></input>
                    <div
                      className="contact-form-label reset-label"
                      style={{ marginTop: "35px" }}
                    >
                      Confirm password
                    </div>
                    <input
                      type="password"
                      className="contact-form-input contact-small-input"
                      placeholder="Placeholder"
                      value={this.state.password2}
                      onChange={e =>
                        this.setState({ password2: e.target.value })
                      }
                    ></input>
                    <div
                      className="contact-submit-button"
                      style={{ marginTop: "35px" }}
                      onClick={() => this.submit()}
                    >
                      SUBMIT
                    </div>
                  </>
                ) : null}
                <div className="only-mobile">
                  <br />
                  <br />
                  <br />
                </div>
                <div className="only-tablet">
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
              <div className="col reset-image-container">
                <img src={passwordGraphic} className="reset-image" />
              </div>
            </div>
          </div>
          <div style={{ flex: "1" }} />
        </div>
      </Layout>
    )
  }
}
export default ResetPassword
